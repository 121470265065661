
// 姓名脱敏
export const nameConceal = data => {
  if (data) {
    // return data.substring(0, 1) + '**' 
        let str1 = data.substr(0, 1);
        let num1 = data.substr(1).length;
        let str2 = ''
        for (let i = 0; i < num1; i++) {
          str2 += ' *'
        }
        let str3 = str1 + str2;
        return str3;
  }
}

// 手机号脱敏
export const phoneConceal = data => {
  if (data) {
    return data.substring(0, 3) + '****' + data.substring(7, 11)
  }
}

// 身份证号码脱敏
export const IdcardConceal = data => {
  if (data) {
    return data.substring(0, 2) + '**************' + data.substring(16, 18)
  }
}

// 家庭地址脱敏
export const addressConceal = data => {
  if (data) {
    return data.substring(0, data.length-4) + '****' 
  }
}
// 银行卡号脱敏 19位
export const bankCardConceal = data => {
  if (data) {
    return data.substring(0, 2) + '***************' + data.substring(17, 19)
  }
}